@font-face {
font-family: '__GeistSans_2ce99c';
src: url(/_next/static/media/d82a8a076d0105b5-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__GeistSans_Fallback_2ce99c';src: local("Arial");ascent-override: 90.51%;descent-override: 21.64%;line-gap-override: 9.84%;size-adjust: 101.65%
}.__className_2ce99c {font-family: '__GeistSans_2ce99c', '__GeistSans_Fallback_2ce99c'
}.__variable_2ce99c {--font-geist-sans: '__GeistSans_2ce99c', '__GeistSans_Fallback_2ce99c'
}

body {
  overflow-y: scroll;
}

.tooltip {
  max-width: 300px;
  text-align: center;
}

