@import '@tabler/core/dist/css/tabler.min.css';

body {
  overflow-y: scroll;
}

.tooltip {
  max-width: 300px;
  text-align: center;
}
